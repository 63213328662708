import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas,ListGroup } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

function Selector() {

  /* Variables de menú lateral */
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  /* Variables del estado del menú radial */
  const enlaceMenuRadialInicial = {
    url: '/actividades-espirituales',
    etiqueta: 'Espirituales',
    icono: iconoEspiritual,
    color: 'bg_espirituales'
}

  const [enlaceMenuRadial, setEnlaceMenuRadial] = useState(enlaceMenuRadialInicial);

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-10'>
            <h1 className='titulo_seccion'>HOY DECIDO POTENCIAR MI PARTE...</h1>
            <p className='descripcion_seccion'>
              Tu termómetro integral marca:
            </p>
            <div className='d-flex w-100 justify-content-center'>
              <img src={iconoEspiritual} className="medalla_selector medalla_espiritual" alt="logo" />
              <p className='etiqueta_selector'>Espiritual</p>
              <p className='porcentaje_selector'>65%</p>
            </div>
            <p className='text-center'>Es tu nivel más bajo hoy</p>    
            <p className='text-center'>Selecciona el área a trabajar</p>            
            <div className='contenedor_grafico'>
              <ul className='menu_radial'>
                <li>
                  <a href="#">
                    <img src={iconoEspiritual} width="80" alt="logo" onClick={() => setEnlaceMenuRadial({
                    url: '/actividades-espirituales',
                    etiqueta: 'Espirituales',
                    icono: iconoEspiritual,
                    color: 'bg_espirituales'
                  })} />
                  </a>
                </li>
                <li>
                  <a href="#">
                  <img src={iconoFisico} width="80" alt="logo" onClick={() => setEnlaceMenuRadial({
                    url: '/actividades-fisicos',
                    etiqueta: 'Fisicos',
                    icono: iconoFisico,
                    color: 'bg_fisicos'
                  })} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={iconoIntelectual} width="80" alt="logo" onClick={() => setEnlaceMenuRadial({
                      url: '/actividades-intelectuales',
                      etiqueta: 'Intelectuales',
                      icono: iconoIntelectual,
                      color: 'bg_intelectuales'
                    })} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={iconoRelacional} width="80" alt="logo" onClick={() => setEnlaceMenuRadial({
                      url: '/actividades-relacionales',
                      etiqueta: 'Relacionales',
                      icono: iconoRelacional,
                      color: 'bg_relacionales'
                    })} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={iconoEmocional} width="80" alt="logo" onClick={() => setEnlaceMenuRadial({
                      url: '/actividades-emocionales',
                      etiqueta: 'Emocionales',
                      icono: iconoEmocional,
                      color: 'bg_emocionales'
                    })} />
                  </a>
                </li>
                <li>
                  <Link to={enlaceMenuRadial.url} className={enlaceMenuRadial.color}>
                    <img src={enlaceMenuRadial.icono} width="80" alt="logo" />
                    {enlaceMenuRadial.etiqueta}
                  </Link>
                </li>
              </ul>
            </div>
          </div> 
        </div>
      </div>
    </div>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='boton_menu_sencillo'>Termómetro</Link>
          <Link to="/login" className='boton_menu_sencillo'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Selector;
