import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Inicio from './Inicio';


function App() {
  return (
    <Inicio/>
  );
}

export default App;
