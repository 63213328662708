import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import InfoEspiritual from './img/info_espiritual.svg';
import InfoFisico from './img/info_fisico.svg';
import InfoIntelectual from './img/info_intelectual.svg';
import InfoRelacional from './img/info_relacional.svg';
import InfoEmocional from './img/info_emocional.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas,ListGroup, Modal  } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import Grafica from './GraficaDonas';
import { useNavigate, Link } from 'react-router-dom';



function Termometro() {

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleClose4 = () => setShow4(false);
  const handleClose5 = () => setShow5(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const handleShow4 = () => setShow4(true);
  const handleShow5 = () => setShow5(true);

  const navigate = useNavigate();

  /* Valores de grafica */

  const [grafica, setGrafica] = useState([Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100)]);

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-10'>
            <h1 className='titulo_seccion'>MI TERMÓMETRO INTEGRAL</h1>
            <p className='descripcion_seccion'>
            ¿Cómo estan hoy mis niveles de bienestar?
            </p>
          </div> 
        </div>
      </div>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <div className='contenedor_grafico'>
              <Grafica series={grafica}/>
            </div>
          </div> 
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-10'>
            <ul className='list list-unstyled'>
              <li className='d-flex justify-content-between' onClick={handleShow1}><img src={InfoEspiritual} className="info_icono" alt="" /> Espiritual<span>{grafica[0]}%</span></li>
              <li className='d-flex justify-content-between' onClick={handleShow2}><img src={InfoFisico} className="info_icono" alt="" /> Físico<span>{grafica[1]}%</span></li>
              <li className='d-flex justify-content-between' onClick={handleShow3}><img src={InfoIntelectual} className="info_icono" alt="" /> Intelectual<span>{grafica[2]}%</span></li>
              <li className='d-flex justify-content-between' onClick={handleShow4}><img src={InfoRelacional} className="info_icono" alt="" /> Relacional<span>{grafica[3]}%</span></li>
              <li className='d-flex justify-content-between' onClick={handleShow5}><img src={InfoEmocional} className="info_icono" alt="" /> Emocional<span>{grafica[4]}%</span></li>
            </ul>
            <hr/>
            <Link to="/selector">Trabajemos en tus objetivos</Link>
            <hr/>
            <Modal show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Espiritual</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>El bienestar espiritual es el cultivo de nuestro interior, que es una fuente de energía, que nos inspira y nos transforma, nos conecta con nosotros mismos y con la esencia de las cosas. Es a través del Mindfulness, la consciencia del momento presente que elevamos lo ordinario a lo extraordinario.</p> 
                <p>Experimentar el bienestar espiritual va más allá de lo material, no es una religión o una doctrina, es cuidar y mimar tu interior, permitir que tu corazón cultive tus valores y se enfoque en tu pasión y propósito de vida.</p>
                <p>Es el componente de trascendencia que se le otorga a la vida.</p>

              </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>Físico</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>El bienestar físico está ligado al cuidado de la salud y el cuerpo; con la capacidad de adoptar hábitos saludables de descanso y recuperación, de alimentación y ejercicio. </p>
                <p>Cuerpo y mente están conectados, así que, el cuidado del cuerpo está ligado con las emociones, lo que comemos interviene en la química intestinal que se manifiesta en el estado de ánimo y en las funciones cerebrales. El ejercicio interviene en la cognición y en el buen funcionamiento de nuestros órganos internos. Todo está interconectado. </p>
              </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3}>
              <Modal.Header closeButton>
                <Modal.Title>Intelectual</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>El bienestar intelectual tiene que ver con el deseo y la capacidad de expandir nuestros conocimientos y habilidades mediante el aprendizaje constante, la curiosidad,la apertura a nuevas ideas, el razonamiento creativo y la exploración.</p>
                <p>Tener un aprendizaje activo constante a través de la lectura “de todo”, practicar pasatiempos, conectar con las artes, la música, los viajes, el baile. Hay una interconexión cerebral entre el aprendizaje y el resto del cuerpo.</p>
                <p>Ayuda a tu cerebro a cambiar y a adaptarse a nuevas experiencias enriquecedoras. Los beneficios se verán reflejados en una mejor congnición, concentración, memoria y una mentalidad más amplia. </p>
              </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={handleClose4}>
              <Modal.Header closeButton>
                <Modal.Title>Relacional</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>El bienestar relacional está constituido por la habilidad de establecer vínculos constructivos con los demás y con nosotros mismos; por el cultivo de las relaciones positivas y significativas.</p>
                <p>Las relaciones son cruciales para una vida plena, por tanto, hay que considerar a las relaciones una prioridad. </p>
                <p>Está relacionado con la capacidad de crear entornos armónicos y cooperativos.</p>
              </Modal.Body>
            </Modal>
            <Modal show={show5} onHide={handleClose5}>
              <Modal.Header closeButton>
                <Modal.Title>Emocional</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>El bienestar emocional tiene que ver con experimentar todo el rango de emociones contactando nuestra capacidad de resiliencia y optimismo. </p>
                <p>Darnos el permiso de ser humanos, aceptar y acoger completamente las emociones placenteras y las desagradables, las alegres y las dolorosas. </p>
                <p>Entender que las emociones forman parte de nuestra naturaleza humana y que al saber gestionarlas ayudarán a desarrollar nuestro máximo potencial.  </p>
              </Modal.Body>
            </Modal>
          </div> 
        </div>
      </div>
    </div>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='card card-body boton_actividad_individual'>Termómetro</Link>
          <Link to="/login" className='card card-body boton_actividad_individual'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Termometro;
