import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/up_logo.svg';
import { FaUserAlt, FaUnlockAlt, FaFacebookF, FaGoogle, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";


function Login() {
  return (
    <div className='fondo_principal d-flex align-items-center justify-content-center'>
      <div className='d-flex flex-column  text-center'>
        <img src={logo} className="mini_logo" alt="logo" />
        <h1 className='py-4 my-4'>Hola</h1>
        <form>
          <div className='form-group mb-3'>
            <label htmlFor='correo'><FaUserAlt /> Usuario</label>
            <input type='text' name='correo' className='form-control form-inicio'/>
          </div>
          <div className='form-group mb-3'>
            <label htmlFor='pass'><FaUnlockAlt /> Contraseña</label>
            <input type='password' name='pass' className='form-control form-inicio'/>
          </div>
          <Link to="/termometro"  type='button' className='btn btn-outline-light my-2'>Iniciar Sesión</Link>
        </form>
        <hr/>
        <Link to="/termometro" className='btn btn-link mb-3 text-white'><FaInstagram /> Continuar con Instragram</Link>
        <Link to="/termometro" className='btn btn-link mb-3 text-white'><FaFacebookF /> Continuar con Facebook</Link>
        <Link to="/termometro" className='btn btn-link mb-3 text-white'><FaGoogle /> Continuar con Google</Link>
        <Link to="/termometro">Termometro</Link>
      </div>
    </div>
  );
}

export default Login;
