import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import InfoEspiritual from './img/info_espiritual.svg';
import InfoFisico from './img/info_fisico.svg';
import InfoIntelectual from './img/info_intelectual.svg';
import InfoRelacional from './img/info_relacional.svg';
import InfoEmocional from './img/info_emocional.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas,ListGroup, Modal, ProgressBar  } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import Grafica from './GraficaDonas';
import { useNavigate, Link } from 'react-router-dom';



function MiPerfil() {

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleClose3 = () => setShow3(false);
  const handleClose4 = () => setShow4(false);
  const handleClose5 = () => setShow5(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);
  const handleShow3 = () => setShow3(true);
  const handleShow4 = () => setShow4(true);
  const handleShow5 = () => setShow5(true);

  const navigate = useNavigate();

  /* Valores de grafica */

  const [grafica, setGrafica] = useState([Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100), Math.round(Math.random()*100)]);

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-10 contenedor_perfil'>
            <div className='foto_perfil'></div>
            <h2 className=''>Hola</h2>
            <h1 className='titulo_seccion'>Tania Saenz</h1>
            <p className='descripcion_seccion'>
            Un lugar para revisar tu actividad
            </p>
            <p>Horas dedicadas a cada objetivo</p>
          </div> 
        </div>
      </div>
      <div className='container-fluid'>
        
        <div className='row justify-content-center'>
          <div className='col-6 text-center my-3'>
            <div className='fondo_actividad_espiritual fondo_actividad_perfil_1'>
            <img src={iconoEspiritual} className="medalla_perfil" alt="Espiritual" /> 
            </div>
            
            <p>ESPIRITUAL<br></br><small>120 Horas</small></p>
            <button className='btn btn-link btn-sm boton_menu_espiritual' onClick={handleShow1}>Ver actividad</button>
          </div> 
          <div className='col-6 text-center my-3'>
            <div className='fondo_actividad_fisica fondo_actividad_perfil_2'>
            <img src={iconoFisico} className="medalla_perfil" alt="Espiritual" /> 
            </div>
            <p>FÍSICO<br></br><small>50 Horas</small></p>
            <button className='btn btn-link btn-sm boton_menu_fisico' onClick={handleShow2}>Ver actividad</button>
          </div> 
          <div className='col-12 text-center my-3'>
            <div className='fondo_actividad_intelectual fondo_actividad_perfil_3'>
            <img src={iconoIntelectual} className="medalla_perfil" alt="Espiritual" /> 
            </div>
            <p>INTELECTUAL<br></br><small>100 Horas</small></p>
            <button className='btn btn-link btn-sm boton_menu_intelectual' onClick={handleShow3}>Ver actividad</button>
          </div> 
          <div className='col-6 text-center my-3'>
            <div className='fondo_actividad_relacional fondo_actividad_perfil_4'>
            <img src={iconoRelacional} className="medalla_perfil" alt="Espiritual" /> 
            </div>
            <p>RELACIONAL<br></br><small>95 Horas</small></p>
            <button className='btn btn-link btn-sm boton_menu_relacional' onClick={handleShow4}>Ver actividad</button>
          </div> 
          <div className='col-6 text-center my-3'>
            <div className='fondo_actividad_emocional fondo_actividad_perfil_5'>
            <img src={iconoEmocional} className="medalla_perfil" alt="Espiritual" /> 
            </div>
            <p>EMOCIONAL<br></br><small>60 Horas</small></p>
            <button className='btn btn-link btn-sm boton_menu_emocional' onClick={handleShow5}>Ver actividad</button>
          </div> 
        </div>
      </div>
    </div>
    <Modal show={show1} onHide={handleClose1}>
      <Modal.Header closeButton>
        <Modal.Title>Espiritual</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
          <div className='col-12'>
            <div className='text-center fondo_actividad_espiritual fondo_actividad_perfil_3'>
              <img src={iconoEspiritual} className="medalla_perfil" alt="Espiritual" /> 
            </div>
          </div>
          <div className='col-12'>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEspiritual} className="info_icono" alt="" /> Meditación </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_espiritual' now={60} /></div>
                <div className='col-3'>10Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEspiritual} className="info_icono" alt="" /> Respiración </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_espiritual' now={90} /></div>
                <div className='col-3'>15Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEspiritual} className="info_icono" alt="" /> Naturaleza </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_espiritual' now={30} /></div>
                <div className='col-3'>8Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEspiritual} className="info_icono" alt="" /> Propósito </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_espiritual' now={10} /></div>
                <div className='col-3'>3Hrs.</div>
              </div>
            </div>
            
          </div>
        </div>
        <hr/>
        <a href='calendario' className='btn btn-link boton_menu_espiritual'> Ver calendario</a>
      </Modal.Body>
    </Modal>
    <Modal show={show2} onHide={handleClose2}>
      <Modal.Header closeButton>
        <Modal.Title>Físico</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
          <div className='col-12'>
            <div className='text-center fondo_actividad_fisica fondo_actividad_perfil_3'>
              <img src={iconoFisico} className="medalla_perfil" alt="Espiritual" /> 
            </div>
          </div>
          <div className='col-12'>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoFisico} className="info_icono" alt="" /> Alimentación </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_fisico' now={60} /></div>
                <div className='col-3'>10Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoFisico} className="info_icono" alt="" /> Ejercicio </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_fisico' now={90} /></div>
                <div className='col-3'>15Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoFisico} className="info_icono" alt="" /> Descanso </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_fisico' now={30} /></div>
                <div className='col-3'>8Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoFisico} className="info_icono" alt="" /> Tacto </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_fisico' now={10} /></div>
                <div className='col-3'>3Hrs.</div>
              </div>
            </div>
            
          </div>
        </div>
        <hr/>
        <a href='calendario' className='btn btn-link boton_menu_fisico'> Ver calendario</a>
      </Modal.Body>
    </Modal>
    <Modal show={show3} onHide={handleClose3}>
      <Modal.Header closeButton>
        <Modal.Title>Intelectual</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
          <div className='col-12'>
            <div className='text-center fondo_actividad_intelectual fondo_actividad_perfil_3'>
              <img src={iconoIntelectual} className="medalla_perfil" alt="Espiritual" /> 
            </div>
          </div>
          <div className='col-12'>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoIntelectual} className="info_icono" alt="" /> Lectura </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_intelectual' now={60} /></div>
                <div className='col-3'>10Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoIntelectual} className="info_icono" alt="" /> Aprendizaje profundo </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_intelectual' now={90} /></div>
                <div className='col-3'>15Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoIntelectual} className="info_icono" alt="" /> Curiosidad </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_intelectual' now={30} /></div>
                <div className='col-3'>8Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoIntelectual} className="info_icono" alt="" /> Enseñar Algo </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_intelectual' now={10} /></div>
                <div className='col-3'>3Hrs.</div>
              </div>
            </div>
            
          </div>
        </div>
        <hr/>
        <a href='calendario' className='btn btn-link boton_menu_intelectual'> Ver calendario</a>
      </Modal.Body>
    </Modal>
    <Modal show={show4} onHide={handleClose4}>
      <Modal.Header closeButton>
        <Modal.Title>Relacional</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
          <div className='col-12'>
            <div className='text-center fondo_actividad_relacional fondo_actividad_perfil_3'>
              <img src={iconoRelacional} className="medalla_perfil" alt="Espiritual" /> 
            </div>
          </div>
          <div className='col-12'>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoRelacional} className="info_icono" alt="" /> Conmigo </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_relacional' now={60} /></div>
                <div className='col-3'>10Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoRelacional} className="info_icono" alt="" /> Comparte </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_relacional' now={90} /></div>
                <div className='col-3'>15Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoRelacional} className="info_icono" alt="" /> Se amable </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_relacional' now={30} /></div>
                <div className='col-3'>8Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoRelacional} className="info_icono" alt="" /> Detox digital </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_relacional' now={10} /></div>
                <div className='col-3'>3Hrs.</div>
              </div>
            </div>
            
          </div>
        </div>
        <hr/>
        <a href='calendario' className='btn btn-link boton_menu_relacional'> Ver calendario</a>
      </Modal.Body>
    </Modal>
    <Modal show={show5} onHide={handleClose5}>
      <Modal.Header closeButton>
        <Modal.Title>Emocional</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className='row'>
          <div className='col-12'>
            <div className='text-center fondo_actividad_emocional fondo_actividad_perfil_3'>
              <img src={iconoEmocional} className="medalla_perfil" alt="Espiritual" /> 
            </div>
          </div>
          <div className='col-12'>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEmocional} className="info_icono" alt="" /> Ser Humano </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_emocional' now={60} /></div>
                <div className='col-3'>10Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEmocional} className="info_icono" alt="" /> Gratitud </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_emocional' now={90} /></div>
                <div className='col-3'>15Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEmocional} className="info_icono" alt="" /> Saborear </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_emocional' now={30} /></div>
                <div className='col-3'>8Hrs.</div>
              </div>
            </div>
            <div className='contenedor_graficas_espiritual'>
              <div> <img src={InfoEmocional} className="info_icono" alt="" /> Hacer como si </div>
              <div className='row'>
                <div className='col-9'><ProgressBar className='progress_emocional' now={10} /></div>
                <div className='col-3'>3Hrs.</div>
              </div>
            </div>
            
          </div>
        </div>
        <hr/>
        <a href='calendario' className='btn btn-link boton_menu_emocional'> Ver calendario</a>
      </Modal.Body>
    </Modal>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='boton_menu_sencillo'>Termómetro</Link>
          <Link to="/login" className='boton_menu_sencillo'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MiPerfil;
