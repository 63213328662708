import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import iconoEnsenar from './img/up_icono_ensenar.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas,ListGroup } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import YouTube from 'react-youtube';

function IntelectualesEnsenar() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco fondo_intelectuales'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <img src={iconoEnsenar} className="icono_seccion" alt="logo" />
          </div>
          <div className='col-10'>
            <h1 className='titulo_seccion'>ENSEÑAR ALGO</h1>
            <p className='descripcion_seccion'>Enseñar es la mejor forma de aprender. Es una oportunidad de crecimiento, es ser un eterno estudiante ya que aprenderás también de ti mismo. Es una motivación para indagar en diferentes temáticas y compartir. Es experimentar la alegría de hacer una diferencia y generar vínculos interpersonales.</p>
            <hr></hr>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_intelectual'>Sobre tí</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_intelectual'>Sobre tu pasión</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_intelectual'>Sobre tu trabajo</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_intelectual'>Sobre la vida</Link>
          </div>
        </div>
      </div>
    </div>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='boton_menu_sencillo'>Termómetro</Link>
          <Link to="/login" className='boton_menu_sencillo'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default IntelectualesEnsenar;
