import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route, } from "react-router-dom";

import Inicio from './Inicio';
import Login from './Login';

import Registro from './Registro';
import Termometro from './Termometro';
import Calendario from './Calendario';
import Selector from './Selector';
import MiPerfil from './MiPerfil';
import ActividadesEspirituales from './ActividadesEspirituales';
import ActividadesFisicos from './ActividadesFisicos';
import ActividadesIntelectuales from './ActividadesIntelectuales';
import ActividadesRelacionales from './ActividadesRelacionales';
import ActividadesEmocionales from './ActividadesEmocionales';

import EspiritualesMeditacion from './EspiritualesMeditacion';
import EspiritualesRespiracion from './EspiritualesRespiracion';
import EspiritualesNaturaleza from './EspiritualesNaturaleza';
import EspiritualesProposito from './EspiritualesProposito';

import FisicosAlimentacion from './FisicosAlimentacion';
import FisicosEjercicio from './FisicosEjercicio';
import FisicosDescanso from './FisicosDescanso';
import FisicosTacto from './FisicosTacto';

import IntelectualesLectura from './IntelectualesLectura';
import IntelectualesAprendizaje from './IntelectualesAprendizaje';
import IntelectualesCuriosidad from './IntelectualesCuriosidad';
import IntelectualesEnsenar from './IntelectualesEnsenar';

import RelacionalesConmigo from './RelacionalesConmigo';
import RelacionalesComparte from './RelacionalesComparte';
import RelacionalesAmable from './RelacionalesAmable';
import RelacionalesDetox from './RelacionalesDetox';

import EmocionalesHumano from './EmocionalesHumano';
import EmocionalesGratitud from './EmocionalesGratitud';
import EmocionalesSaborear from './EmocionalesSaborear';
import EmocionalesHacer from './EmocionalesHacer';


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="login" element={<Login />} />
      <Route path="registro" element={<Registro />} />
      <Route path="termometro" element={<Termometro />} />
      <Route path="calendario" element={<Calendario />} />
      <Route path="selector" element={<Selector />} />
      <Route path="mi-perfil" element={<MiPerfil />} />
      <Route path="actividades-espirituales" element={<ActividadesEspirituales />} />
      <Route path="actividades-fisicos" element={<ActividadesFisicos />} />
      <Route path="actividades-intelectuales" element={<ActividadesIntelectuales />} />
      <Route path="actividades-relacionales" element={<ActividadesRelacionales />} />
      <Route path="actividades-emocionales" element={<ActividadesEmocionales />} />
      <Route path="espirituales/meditacion" element={<EspiritualesMeditacion />} />
      <Route path="espirituales/respiracion" element={<EspiritualesRespiracion />} />
      <Route path="espirituales/naturaleza" element={<EspiritualesNaturaleza />} />
      <Route path="espirituales/proposito" element={<EspiritualesProposito />} />
      <Route path="fisicos/alimentacion" element={<FisicosAlimentacion />} />
      <Route path="fisicos/ejercicio" element={<FisicosEjercicio />} />
      <Route path="fisicos/descanso" element={<FisicosDescanso />} />
      <Route path="fisicos/tacto" element={<FisicosTacto />} />
      <Route path="intelectuales/lectura" element={<IntelectualesLectura />} />
      <Route path="intelectuales/aprendizaje" element={<IntelectualesAprendizaje />} />
      <Route path="intelectuales/curiosidad" element={<IntelectualesCuriosidad />} />
      <Route path="intelectuales/ensenar" element={<IntelectualesEnsenar />} />
      <Route path="relacionales/conmigo" element={<RelacionalesConmigo />} />
      <Route path="relacionales/comparte" element={<RelacionalesComparte />} />
      <Route path="relacionales/amable" element={<RelacionalesAmable />} />
      <Route path="relacionales/detox" element={<RelacionalesDetox />} />
      <Route path="emocionales/humano" element={<EmocionalesHumano />} />
      <Route path="emocionales/gratitud" element={<EmocionalesGratitud />} />
      <Route path="emocionales/saborear" element={<EmocionalesSaborear />} />
      <Route path="emocionales/hacer" element={<EmocionalesHacer />} />
    </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
