import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoAlimentacion from './img/up_icono_alimentacion.svg';
import iconoEjercicio from './img/up_icono_ejercicio.svg';
import iconoDescanso from './img/up_icono_descanso.svg';
import iconoTacto from './img/up_icono_tacto.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

function ActividadesFisicos() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco fondo_fisicos'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <img src={iconoFisico} className="icono_seccion" alt="logo" />
          </div>
          <div className='col-10'>
          <h1 className='titulo_seccion'>FÍSICO</h1>
            <h1 className='titulo_seccion'>MIS OBJETIVOS</h1>
            <div className='row contenedor_botones_actividades'>
              <div className='col-6 mb-3'>
                <Link to="/fisicos/alimentacion">
                  <div className='card card-body boton_actividad boton_actividad_1 fondo_actividad_fisica'>
                    <p className='titulo_boton_actividad'>Alimentación</p>
                    <img src={iconoAlimentacion} className="imagen_boton_actividad" alt="Alimentación" />
                  </div>    
                </Link>
              </div>  
              <div className='col-6 mb-3'>
                <Link to="/fisicos/ejercicio">
                  <div className='card card-body boton_actividad boton_actividad_2 fondo_actividad_fisica'>
                    <p className='titulo_boton_actividad'>Ejercicio</p>
                    <img src={iconoEjercicio} className="imagen_boton_actividad" alt="Ejercicio" />
                  </div>  
                </Link>
              </div>  
              <div className='col-6 mb-3'>
                <Link to="/fisicos/descanso">
                  <div className='card card-body boton_actividad boton_actividad_3 fondo_actividad_fisica'>
                    <p className='titulo_boton_actividad'>Descanso</p>
                    <img src={iconoDescanso} className="imagen_boton_actividad" alt="Descanso" />
                  </div>
                </Link>
              </div>  
              <div className='col-6 mb-3'>
                <Link to="/fisicos/tacto">
                <div className='card card-body boton_actividad boton_actividad_4 fondo_actividad_fisica'>
                  <p className='titulo_boton_actividad'>Tacto</p>
                  <img src={iconoTacto} className="imagen_boton_actividad" alt="Tacto" />
                </div>  
                </Link>
              </div>  
            </div>
          </div> 
        </div>
      </div>
    </div>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='boton_menu_sencillo'>Termómetro</Link>
          <Link to="/login" className='boton_menu_sencillo'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default ActividadesFisicos;
