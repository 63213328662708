import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logoBlanco from './img/up_logo_letras_negro.svg';
import iconoGratitud from './img/up_icono_gratitud.svg';
import iconoEspiritual from './img/up_icono_espiritual.svg';
import iconoFisico from './img/up_icono_fisico.svg';
import iconoIntelectual from './img/up_icono_intelectual.svg';
import iconoRelacional from './img/up_icono_relacionales.svg';
import iconoEmocional from './img/up_icono_emocionales.svg';
import {Button, Offcanvas,ListGroup } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import{ FaChevronLeft } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import YouTube from 'react-youtube';

function EmocionalesGratitud () {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <>
    <div className='menu-contenedor'>
      <div className='d-flex justify-content-between'>
        <div className='d-flex'>
          <Button variant="light" onClick={() => navigate(-1)}>
            <FaChevronLeft/>
          </Button>
        </div>
        <div className='d-flex'>
          <img src={logoBlanco} className="mini_logo" alt="logo" />
        </div>
        <div className='d-flex'>
        <Button variant="light" onClick={handleShow}>
          <GiHamburgerMenu/>
        </Button>
        </div>
      </div>
      
    </div>
    <div className='fondo_blanco fondo_emocionales'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            <img src={iconoGratitud} className="icono_seccion" alt="logo" />
          </div>
          <div className='col-10'>
            <h1 className='titulo_seccion'>GRATITUD</h1>
            <p className='descripcion_seccion'>Es uno de los potenciadores de placer más poderoros. Apreciar los aspectos de la vida y reconocer la maravilla que es el mundo y los demás. Las personas agradecidas son más felices y están mas satisfechas con la vida, sis amistades, su familia, su comunidad y ellas mismas.</p>
            <hr></hr>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_emocional'>Diario de gratitud</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_emocional'>Agradécete a ti mismo (ejerc.psi.positiva)</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_emocional'>Agradece a otro (explicando el porqué)</Link>
            <Link to="/" className='card card-body boton_actividad_individual boton_menu_emocional'>Agradece a tus antepasados (curso reuniones ef.)</Link>
          </div>
        </div>
      </div>
    </div>

    <Offcanvas placement='end' show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>MENÚ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='cuerpo_menu'>
          <h3>Hola Tania</h3>
          <div>
          <Link to="/mi-perfil" className='card card-body boton_actividad_individual'>Mi perfil</Link>
          
          </div>
          <h3>Mis objetivos</h3>
          <div>
          <Link to="/actividades-espirituales" className='card card-body boton_actividad_individual boton_menu_espiritual'> <img src={iconoEspiritual} className="medalla_menu" alt="Espiritual" /> Espiritual</Link>
          <Link to="/actividades-fisicos" className='card card-body boton_actividad_individual boton_menu_fisico'> <img src={iconoFisico} className="medalla_menu" alt="Espiritual" />  Físico     &nbsp;&nbsp;&nbsp;&nbsp;</Link>
          <Link to="/actividades-intelectuales" className='card card-body boton_actividad_individual boton_menu_intelectual'> <img src={iconoIntelectual} className="medalla_menu" alt="Espiritual" />  Intelectual</Link>
          <Link to="/actividades-relacionales" className='card card-body boton_actividad_individual boton_menu_relacional'><img src={iconoRelacional} className="medalla_menu" alt="Espiritual" />  Relacional</Link>
          <Link to="/actividades-emocionales" className='card card-body boton_actividad_individual boton_menu_emocional'><img src={iconoEmocional} className="medalla_menu" alt="Espiritual" />  Emocional</Link>
          </div>
          <div>
          <Link to="/termometro" className='boton_menu_sencillo'>Termómetro</Link>
          <Link to="/login" className='boton_menu_sencillo'>Login</Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default EmocionalesGratitud ;
