import React, { Component } from "react";
import Chart from "react-apexcharts";

class Grafica extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "radial-bar"
        },
        track: {
          show: false,
          background: '#f6d554',
          strokeWidth: '100%',
          opacity: 0,
          margin: 5, 
        },
        fill: {
          type: 'solid',
          colors:['#6a7eff', '#3ee1e0', '#f6d554','#f49b4c', '#fe6656']
        }
      },
      series: props.series,
      labels: ['Espiritual', 'Física', 'Intelectual', 'Relacional', 'Emocional']
    };
  }

  render() {
    return (
      <div className="app">
        <div className="row">
          <div className="mixed-chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="radialBar"
              height="390"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Grafica;
