import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './img/up_logo.svg';
import logoBlanco from './img/up_logo_letras_blanco.svg';
import { Link } from "react-router-dom";


function Inicio() {
  return (
    <div className='fondo_principal d-flex align-items-center justify-content-center'>
      <div className='d-flex flex-column  text-center'>
        <img src={logoBlanco} className="logo" alt="logo" />
        <img src={logo} className="logo" alt="logo" />
        <div className='login_select'>
          <Link to="/login">Ingresar</Link>
          <Link to="/registro">Registrarme</Link>
        </div>
        </div>
    </div>
  );
}

export default Inicio;
